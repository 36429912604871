<template>
  <div class="row filter-row">
    <div class="col-sm-6 col-md-3">
      <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
        <input
          type="text"
          class="form-control floating"
          v-model="employeeID"
          @focus="isFocused = true"
          @blur="isFocused = employeeID !== ''"
          @keyup.enter="searchEmployee"
        />
        <label class="focus-label">Employee ID</label>
      </div>
    </div>
    <div class="col-sm-6 col-md-3">
      <div class="input-block mb-3 form-focus" :class="{ focused: isFocusedOn }">
        <input
          type="text"
          class="form-control floating"
          v-model="employeeName"
          @focus="isFocusedOn = true"
          @blur="isFocusedOn = employeeName !== ''"
          @keyup.enter="searchEmployee"
        />
        <label class="focus-label">Employee Name</label>
      </div>
    </div>
    <div class="col-sm-6 col-md-3">
      <div class="h-100">
        <a-select
            v-model:value="value"
            show-search
            placeholder="Select a Designation"
            style="width: 100%;"
            :options="optionsEmpDesignation"
            @change="handleChange"
          >
          </a-select>
        <!-- <select v-model="designation_id" class="form-select form-select-lg" @change="searchEmployee">
          <option value="">All</option>
          <option v-for="desgntn in Designations" :key="desgntn.id" :value="desgntn.id">{{ desgntn.name }}</option>
        </select> -->
      </div>
    </div>
    <div class="col-sm-6 col-md-3">
      <a href="javascript:;" class="btn btn-success w-100 submit-btn" @click="searchEmployee"> Search </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapMutations,mapActions, mapGetters } from 'vuex';
export default {
  emits:['reload-data'],
  data() {
    return {
      select2: null,
      Company: ["Select Company", "Global Technologies", "Delta Infotech"],
      employeeID: "",
      isFocused: false,
      employeeName: "",
      isFocusedOn: false,
      designation_id:"",
      optionsEmpDesignation:[],
      value:null
    };
  },
  watch:{
    employeeID(newVal){
      this.updateData()
    },
    employeeName(newVal){
      this.updateData()
    },
    designation_id(newVal){
      this.updateData()
    }
  },
  methods: {
    ...mapActions(['fetchEmployees']),
    ...mapMutations(['SET_EMPLOYEE_SEARCH_FIELD']),
    updateData()
    {
      this.SET_EMPLOYEE_SEARCH_FIELD(
        {
          employee_id:this.employeeID,
          name:this.employeeName,
          designation_id:this.designation_id
        }
      )
    },
    async searchEmployee()
    {
      this.$emit('reload-data',this.getEMployeeSeatchData);
      await this.fetchEmployees(this.getEMployeeSeatchData)
    },
    fetchDesignations()
    {
      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      axios.get('designations/all').then(response =>{
        const designations = response?.data?.data  || []

        if(designations)
        {
          this.optionsEmpDesignation = designations.map(designation => {
            return {
                label: designation.name,
                value: designation.id
            };
          });

          this.optionsEmpDesignation.unshift({label: "All", value: ''})
        }
      }).catch(error => {
        console.log('error during fetching designation',error)
      })
    },
    handleChange(value)
    {

     

      this.$nextTick(()=>{
      this.designation_id = value;
     
        this.updateData()
        this.searchEmployee()
      })
    }
  },
  computed:{
    ...mapGetters(['getEMployeeSeatchData'])
  },
  created(){
    this.fetchDesignations()
  }
};
</script>
<style>
:where(.css-dev-only-do-not-override-1hsjdkk).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  font-weight: 600;
}

</style>
