<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <inbox-header :title="title" :path="path" :text="text" :text1="text1" :hasCreatePermission="hasCreatePermission"/>
        <!-- /Page Header -->
		
		<Form @submit="onSearch">
		 <div class="row filter-row">
			<div class="col-sm-3 col-md-3">
			  <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
				<input
				  type="text"
				  class="form-control floating"
				  v-model="searchform.officename"
				  @focus="isFocused = true"
				  @blur="isFocused = searchform.officename !== ''"
				/>
				<label class="focus-label">Office Name</label>
			  </div>
			</div>
			<div class="col-sm-3 col-md-3">
			  <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
				<input
				  type="text"
				  class="form-control floating"
				  v-model="searchform.address"
				/>
				<label class="focus-label">Address</label>
			  </div>
			</div>
			<div class="col-sm-2 col-md-2">
			   <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
				<input
				  type="text"
				  class="form-control floating"
				  v-model="searchform.city"
				/>
				<label class="focus-label">City</label>
			  </div>
			</div>
			<div class="col-sm-2 col-md-2">
			   <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
				<input
				  type="text"
				  class="form-control floating"
				  v-model="searchform.postcode"
				/>
				<label class="focus-label">Post Code</label>
			  </div>
			</div>
			<div class="col-sm-2 col-md-2">
			  <div class="d-grid">
				<button class="btn btn-success w-100 submit-btn">Search</button> 
			  </div>
			</div>
		  </div>
		</Form>
		
		<div class="row">
          <div class="col-md-12">
            <div class="showentries mb-3">
              <label
                >Show
                <select v-model="pagination.pageSize" @change="updatePerPage">
				  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries</label
              >
            </div>
            <div class="table-responsive">
              <a-table
                class="stripped table-hover"
                :columns="columns"
                :data-source="data"
				:pagination="pagination"
				@change="handleTableChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'Name'">
                    <div>{{ record.Name }}</div>
                  </template>
				  <!--<template v-if="column.key === 'office_company_id'">
                    <div v-if="record.companydetails">{{record.companydetails.company_name}}</div>
                  </template>-->
				  <template v-else-if="column.key === 'action'">
                    <div class="text-end" v-if="hasEditPermission || hasDeletePermission">
                      <div class="dropdown dropdown-action">
                        <a
                          href="javascript:;"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="material-icons">more_vert</i></a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <a v-if="hasEditPermission"
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_office"
							@click="EditOffice(record)"
                            ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                          >
                          <a  v-if="hasDeletePermission"
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_office"
							@click="DeleteOffi(record.office_id)"
                            ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                          >
                        </div>
                      </div>
                    </div>
                  </template>
				  <!--<template v-else-if="column.key === 'status'">
                    <div class="text-left">
                      <div class="dropdown action-label">
                        <a
                          class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                          href="javascript:;"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
						  
						  v-if="record.completed == 1"
                        >
                          <i :class="record.Class"></i> Completed
                        </a>
						<a
                          class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                          href="javascript:;"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
						  
						  v-if="record.completed == 0"
                        >
                          <i :class="record.Class"></i> Pending
                        </a>
						
                        <div class="dropdown-menu dropdown-menu-right">
                          <a class="dropdown-item" href="javascript:;"
                            ><i class="fa-regular fa-circle-dot text-purple"></i> Completed</a
                          >
                          <a class="dropdown-item" href="javascript:;"
                            ><i class="fa-regular fa-circle-dot text-info"></i> Pending</a
                          >
                         
                        </div>
                      </div>
                    </div>
                  </template>-->
                </template>
              </a-table>
            </div>
          </div>
        </div>
		
		<!--
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-striped custom-table mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Holiday Date</th>
                    <th>End Date</th>
                    <th class="text-end">Action</th>
                  </tr>
                </thead>
                <tbody>
				 <tr class="holiday-upcoming" v-for="(item,index) in Holidays" :key="item.id">
					<td>{{ pagination.current === 1 ? index + 1 : (pagination.current - 1) * pagination.pageSize + (index + 1) }}</td>
					<td>{{ item.name }}</td>
					<td>{{ item.holiday_date }}</td>
					<td>{{ item.end_date }}</td>
					<td class="text-end">
                      <div class="dropdown dropdown-action">
                        <a
                          href="javascript:;"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="material-icons">more_vert</i></a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <a
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_holiday"
                            ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                          >
                          <a
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_holiday"
                            ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                          >
                        </div>
                      </div>
                    </td>
				 </tr>
                 <tr class="holiday-upcoming" v-for="item in Holidays" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td>{{ item.Title }}</td>
                    <td>{{ item.HolidayDate }}</td>
                    <td>{{ item.Day }}</td>
                    <td class="text-end">
                      <div class="dropdown dropdown-action">
                        <a
                          href="javascript:;"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="material-icons">more_vert</i></a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <a
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_holiday"
                            ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                          >
                          <a
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_holiday"
                            ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
				  
                </tbody>
              </table>
            </div>
          </div>
        </div> -->
      </div>
      <!-- /Page Content -->

      <office-model :form="create_form" @create-office="createOffice" @update-office="updateOffice" :editform="edit_form" :office_id="officeid" @delete-office="deleteOffice" ref="officemodel" ></office-model>
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>

<script>
var pagination = {total: 0,current: 1,pageSize: 10};
		
const columns = [
  {
	  title: "#",
	  dataIndex: "id",
	  key: "id",
	  customRender: ({ index }) => {
		return pagination.current === 1 ? index + 1 : (pagination.current - 1) * pagination.pageSize + (index + 1);
	  },
  },
 /* {
    title: "Company",
    dataIndex: "office_company_id",
    key: "office_company_id",
	sorter: false,
  },*/
  {
    title: "Office Name",
    dataIndex: "office_name",
    key: "holiday_date",
	sorter: false,
  },
  {
    title: "Address",
    dataIndex: "office_address",
    key: "office_address",
	sorter: false,
  },
  {
    title: "City",
    dataIndex: "office_city",
    key: "office_city",
	sorter: false,
  },
  {
    title: "State",
    dataIndex: "office_state",
    key: "office_state",
	sorter: false,
  },
  {
    title: "Post Code",
    dataIndex: "office_zip",
    key: "office_zip",
	sorter: false,
  },
  {
    title: "Lattitude",
    dataIndex: "office_lattitude",
    key: "office_lattitude",
	sorter: false,
  },
  {
    title: "Longitude",
    dataIndex: "office_longitude",
    key: "office_longitude",
	sorter: false,
  },
];

const data = [];		

		
//import Holidays from "@/assets/json/holidays.json";
import inboxHeader from "@/components/breadcrumb/inbox-header.vue";

import axios from 'axios';
import { notification } from "ant-design-vue";
import moment from 'moment';
import { ref } from "vue";
const currentDate = ref(new Date());
import { Form, Field } from "vee-validate";

export default {
  components: { Form,
    Field,inboxHeader },
  data() {
    return {
      Holidays: {},
      title: "Office",
      path: "Dashboard",
      text: "Office",
      text1: "Add Office",
	  pagination: pagination,
	  columns:columns,
	  data,
	  create_form: {  "office_name": "", "office_address": "", "office_city" :"", "office_state" : "", "office_zip" : "","office_lattitude":"","office_longitude":""},
	  edit_form: {},
	  officeid:0,
	  searchform: {officename: "",address:"",city: "", postcode:""},
	  isFocused: false,
	  hasReadPermission:null,
	  hasCreatePermission:null,
	  hasEditPermission:null,
	  hasDeletePermission:null
    };
  },
  methods: {
   onSearch(){
	
		var params = {
		   params: { officename: this.searchform.officename,address:this.searchform.address, city: this.searchform.city, postcode: this.searchform.postcode,per_page: this.pagination.pageSize }
		};	
		
		this.loadCommonData(params);
		
	},
	deleteOffice(officeid){
		console.log('Parent Called');
		console.log(officeid);
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		axios.delete("/office/"+officeid, [])
          .then( (response) => {
				
			 loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.officemodel.closeDialog();
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	DeleteOffi(officeid){
		this.officeid = officeid;
	},
	updateOffice(formval){
		
		console.log(formval);
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
			
		var postform = new FormData();
		postform.append('office_name',formval.office_name);
		//postform.append('office_company_id',formval.office_company_id);
		postform.append('office_address',formval.office_address);
		postform.append('office_city',formval.office_city);
		postform.append('office_state',formval.office_state);
		postform.append('office_zip',formval.office_zip);
		postform.append('office_status',formval.office_status);
		postform.append('office_lattitude',formval.office_lattitude);
		postform.append('office_longitude',formval.office_longitude);
		
		
		//postform.append('holiday_date',moment(formval.holiday_date).format('YYYY-MM-DD'));
		//postform.append('end_date',moment(formval.end_date).format('YYYY-MM-DD'));
		
		axios.post("/office/update/"+formval.office_id, postform)
          .then( (response) => {
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.officemodel.closeDialog();
			 this.edit_form ={};
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	EditOffice(record){
		
		//this.edit_form = Object.assign({}, record);
		
		var editobj = Object.assign({}, record);
		
		/*if(editobj.holiday_date != ""){
			editobj.holiday_date = new Date(editobj.holiday_date);
		}
		
		if(editobj.end_date != ""){
			editobj.end_date = new Date(editobj.end_date);
		}*/
		
		this.edit_form = editobj;
		
	},
	createOffice(formval){
		console.log('create office called!!..');
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		axios.post("/office/create", formval)
          .then( (response) => {
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.officemodel.closeDialog();
			 
			 this.create_form =  { "office_name": "", "office_address": "", "office_city" :"", "office_state" : "", "office_zip" : "","office_lattitude":"","office_longitude":""};
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
	},
	handleTableChange(pagesize){
		
		console.log('adad');
		console.log(pagesize);
		
		var params = {
		   params: { officename: this.searchform.officename,address:this.searchform.address, city: this.searchform.city, postcode: this.searchform.postcode, per_page: pagesize.pageSize,page:pagesize.current }
		};
		
		this.loadCommonData(params);
		
	},
	updatePerPage(){
		console.log(this.pagination.pageSize);
		var params = {
          params: { per_page: this.pagination.pageSize }
        };
        this.loadCommonData(params);
		
	},
	loadCommonData(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/office/list", params)
			.then((response) => {
				
				
				this.pagination.total = response.data.data.total;
				this.pagination.current = response.data.data.current_page;
				this.pagination.pageSize = response.data.data.per_page;
				
				this.data = response.data.data.data;
				
				loader.hide();
				
				
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
	},
	loadCompanies(){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/company", [])
			.then((response) => {
				
				this.companyList = response.data.record;
				
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
	},
  },
  mounted() {

	
  	this.$store.dispatch('userPermissions', { module: 'office', action: 'read' }).then(response =>{
		this.hasReadPermission=null;
		this.$nextTick(()=>{
			this.hasReadPermission = response;
			if(response)
			{
				this.columns = [...columns,{
					title: "Action",
					sorter: false,
					key: "action",
					class: "text-end",
				}];
			}
		})
	})

	this.$store.dispatch('userPermissions', { module: 'office', action: 'create' }).then(response =>{
		this.hasCreatePermission=null;
		this.$nextTick(()=>{
			this.hasCreatePermission = response;
		})
	})

	this.$store.dispatch('userPermissions', { module: 'office', action: 'write' }).then(response =>{
		this.hasEditPermission=null;
		this.$nextTick(()=>{
			this.hasEditPermission = response;
		})
	})

	this.$store.dispatch('userPermissions', { module: 'office', action: 'delete' }).then(response =>{
		this.hasDeletePermission=null;
		this.$nextTick(()=>{
			this.hasDeletePermission = response;
		})
	})
	
	var params = {
       params: { per_page: this.pagination.pageSize }
    };	
	this.loadCommonData(params);
	
	this.loadCompanies();
	
  }
};
</script>
