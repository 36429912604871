<template>
  <!-- Search Filter -->
  <div class="row filter-row">
    <div class="col-sm-6 col-md-3">
      <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
        <input
          type="text"
          class="form-control floating"
          v-model="employeeID"
          @focus="isFocused = true"
          @blur="isFocused = employeeID !== ''"
          @keyup.enter="searchSchedule"
        />
        <label class="focus-label">Employee</label>
      </div>
    </div>

    <div class="col-sm-6 col-md-3">
      <div class="input-block mb-3 form-focus select-focus">
        <!-- <vue-select :options="Finance" v-model="department_id" id="all-finance" placeholder="All Department" /> -->
        <select @change="searchSchedule" v-model="department_id" class="form-select form-select-lg" placeholder="Select">
          <option v-for="dprtmnt in department" :key="dprtmnt?.id" :value="dprtmnt?.id">{{ dprtmnt?.name }}</option>
        </select>
        <label class="focus-label">Department</label>
      </div>
    </div>
    <div class="col-sm-6 col-md-2">
      <div class="input-block mb-3 form-focus focused">
        <div class="cal-icon">
          <datepicker
            v-model="startdate"
            placeholder="Choose Date"
            class="form-control floating datetimepicker"
            :editable="true"
            :clearable="false"
            :input-format="dateFormat"
            @update:model-value="searchSchedule"
          />
        </div>
        
      </div>
    </div>
    <div class="col-sm-6 col-md-2">
      <div class="input-block mb-3 form-focus focused">
        <div class="cal-icon">
          <datepicker
            v-model="startdateone"
            placeholder="Choose Date"
            class="form-control floating datetimepicker"
            :editable="true"
            :clearable="false"
            :input-format="dateFormat"
            @update:model-value="searchSchedule"
          />
        </div>
        
      </div>
    </div>
    <div class="col-sm-6 col-md-2">
      <a href="javascript:;" class="btn btn-success w-100 submit-btn" @click="searchSchedule"> Search </a>
    </div>
  </div>
  <!-- Search Filter -->
</template>

<script>
import { ref } from "vue";
import moment from 'moment';
const currentDate = ref(new Date(moment()));
const currentDateOne = ref(new Date(moment().add(5, 'days')));
import { notification } from "ant-design-vue";
import axios from 'axios';
export default {
  emits:['search-schedule'],
  props:{
    sDate: {
      type: [String, Object],
      required: false,
      default: () => null,
    },
    eDate: {
      type: [String, Object],
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      select2: null,
      Finance: [
        "All Department",
        "Finance",
        "Finance and Management",
        "Hr & Finance",
        "ITech",
      ],
      startdate: currentDate,
      dateFormat: 'dd-MM-yyyy',
      startdateone: currentDateOne,
      employeeID: "",
      department_id:"",
      isFocused: false,
      department:[]
    };
  },
  methods: {
    addFocusClass() {
      this.isFocused = true;
    },
    removeFocusClass() {
      this.isFocused = false;
    },
    searchSchedule(){
      
      if(this.startdate == null || this.startdateone == null || this.startdate == '' || this.startdateone == '')
      {
        notification.open({
              message: 'please select start date and end date both for filter data',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
      }
      else{

        if(new Date(this.startdate) > new Date(this.startdateone))
        {
          const start_date = this.startdateone;
          this.startdateone =  this.startdate;
          this.startdate = start_date
        }

        const params = {
          employee_id:this.employeeID,
          department_id:this.department_id,
          start_date:moment(this.startdate).format('YYYY-MM-DD'),
          end_date:moment(this.startdateone).format('YYYY-MM-DD')
        }
        this.$emit('search-schedule',params);
      }
      
    }
  },
  created()
  {

    this.startdate = currentDate 
    this.startdateone= currentDateOne

    var token = window.localStorage.getItem("token");
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.get('/departments/all').then(response => {
      if(response?.data?.data && response?.data?.data?.length)
      {
        const placeholder = [{id:'',name:'Select'}];
        this.department = [...placeholder,...response?.data?.data];
      }
      else
      {
        this.department = [{id:'',name:'No Records'}];
      }
    }).catch(err => {
      console.log('err',err);
    })
  },
  watch:{
    sDate(newValue){
      if(newValue?._i)
      {
        this.startdate = new Date(newValue?._i);
      }
    },
    eDate(newValue){
      if(newValue?._i)
      {
        this.startdateone = new Date(newValue?._i);
      }
    }
  },
};
</script>
