<template>
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper" ref="formContainer">
        <!-- Page Content -->
        <div class="content container-fluid">
          <!-- Page Header -->
          <reports-header :title="title" :path="path" :text="text" :text1="text1" :search_data="search_data" :columns="columns" />
          <!-- /Page Header -->
          <!-- Content Starts -->
          <div class="row justify-content-center">
            <div class="col-md-3 col-sm-6">
              <div class="card">
                <div class="card-body text-center">
                  <h3><b>{{ pagination?.total }}</b></h3>
                  <p>Total Employees</p>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6">
              <div class="card">
                <div class="card-body text-center">
                  <h3 class="text-success"><b>{{ today_present || 0 }}</b></h3>
                  <p>Total Present</p>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6">
              <div class="card">
                <div class="card-body text-center">
                  <h3 class="text-danger"><b>{{ today_absent || 0 }}</b></h3>
                  <p>Total Absent</p>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6">
              <div class="card">
                <div class="card-body text-center">
                  <h3><b>{{ today_left  || 0}}</b></h3>
                  <p>Total Left</p>
                </div>
            </div>
            </div>
          </div>
          <!-- Search Filter -->
          <employee-reports-filter @filter-data="filterData" :hasEmployeeStatus="false" :end_date_="end_date" :start_date_="start_date"/>
          <!-- /Search Filter -->
          <div class="row">
            <div class="col-md-12">
              <div class="showentries mb-3">
                <label
                  >Show
                  <select v-model="pagination.pageSize" @change="updatePerPage">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  entries
                </label>
              </div>
              <div class="table-responsive">
                <a-table
                  class="stripped table-hover"
                  :columns="columns"
                  :data-source="data"
                  :pagination="pagination"
                          @change="handleTableChange"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Name'">
                      <h2 class="table-avatar">
                        <router-link :to="'profile/'+record?.id" class="avatar"
                          >
                              <img v-if="record?.avatar"
                              :src="getEmployeeAvatar(record.avatar)"
                              alt="User Image"
                              @error="handleImageError(record)"
                              />
                              <img v-else
                              :src="require(`@/assets/img/profiles/${defaultAvatar}`)"
                                  alt="User Image" 
                              />
                        </router-link>
                        <router-link :to="'profile/'+record?.id"
                          >{{ record?.first_name }} {{ record?.last_name }} <span>{{ (record?.employee_id) ? '- #'+record?.employee_id : '' }}</span></router-link
                        >
                      </h2>
                    </template>
                    <template v-if="column.key === 'department'">
                      {{ record?.department?.name  }}
                    </template>
                    <template v-if="column.key != 'Name' && column.key != 'department'">
                      <div v-if="record?.date_data[column.key]?.employeeAttendance">
                        <p class="m-0"> Working Hours : {{ record?.date_data[column.key]?.employeeAttendance?.total_hours_format || 0 }}  Hours </p>
                        <p class="m-0"> Punch In At  {{ convertUTCToLocal(record?.date_data[column.key]?.employeeAttendance?.lastpunch?.checkouttime) || '' }} </p>
                        <p class="m-0"> Punch Out At  {{ convertUTCToLocal(record?.date_data[column.key]?.employeeAttendance?.firstpunch?.checkintime) || '-' }} </p>
                      </div>
                      <div v-else-if="record?.date_data[column.key]?.is_leave">
                        On Leave : {{ record?.date_data[column.key]?.is_leave_type }}
                      </div>
                       <div v-else-if="record?.date_data[column.key]?.is_holiday">
                        Holiday : {{ record?.date_data[column.key]?.is_holiday?.name }}
                       </div>
                       <div v-else>
                        <p class="text-danger">Absent</p>
                       </div>
                      <!-- </div> -->
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
  
          <!-- /Content End -->
        </div>
        <!-- /Page Content -->
      </div>
      <!-- /Page Wrapper -->
    </div>
  </template>
  <script>
  import axios from 'axios';
  import { notification } from "ant-design-vue";
  import moment from 'moment';
  const columns = [
    {
      title: "Employee",
      dataIndex: "Name",
      key: "Name",
      sorter: {
        compare: (a, b) => {
          a = a.first_name.toLowerCase();
          b = b.first_name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      hidden:false,
      visibility:true
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      hidden:false,
      visibility:true
    }
  ];
  
  var pagination = {total: 0,
          current: 1,
          pageSize: 10,};
  export default {
    data() {
      return {
        title: "Attendance Export Report",
        path: "Dashboard",
        text: "Attendance Export Report",
        text1: "Export Excel",
        columns_d:columns,
        columns:null,
        data:[],
        pagination,
        defaultAvatar:'avatar-02.jpg',
        total:0,
        present:0,
        absent:0,
        left:0,
        search_data:null,
        start_date:null,
        end_date:null,
        today_present:0,
        today_absent:0,
        today_left:0
      };
    },
    name: "daily-reports",
    methods:{
      convertUTCToLocal(time) {
      if(time)
      {
        time = time.split(' ')[1] || null;
        const [hours, minutes, seconds] = time.split(':').map(Number);
        // Determine AM or PM
        const period = hours >= 12 ? 'PM' : 'AM';

        let m = minutes;

        // Convert hours to 12-hour format
        let hours12 = hours % 12;
        hours12 = hours12 === 0 ? 12 : hours12; // 0 should be treated as 12 in 12-hour format
        if(m < 10)
        {
          m = '0'+m;
        }

        if(hours12 < 10)
        {
          hours12 = '0'+hours12;
        }

        // Construct the formatted time string
        const formattedTime = `${hours12}:${m}`;

        // Return the formatted time with period
        return `${formattedTime} ${period}`;
      }
      return null;
      },
      updatePerPage(){
        var params = {
              params: { per_page: this.pagination.pageSize }
            };
        this.fetchData(params);
        
      },
      date_(date)
      {
        return moment(date).format('DD MMM YYYY') || '-';
      },
      getEmployeeAvatar(avatar){
        return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
      },
      handleImageError(record) {
        record.avatar = null;
        record.avatar = `assets/img/profiles/${this.defaultAvatar}`;
      },
      handleTableChange(pagesize){
        var params = {
          params: { per_page: pagesize.pageSize,page:pagesize.current }
        };
        this.fetchData(params);
      },
      filterData(searchData = null)
      {
        let params = null;
        if(searchData)
        {
  
          this.search_data = null;
          this.$nextTick(()=>{
            this.search_data = searchData;
          })
          params = {
            params: { 
              per_page: this.pagination.pageSize,
              ...searchData
            }
          };
        }
  
        this.fetchData(params);
        
      },
      fetchData(params = null ){
        var token = window.localStorage.getItem("token");
            axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  
  
        let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: false
        });
  
        if(params == null)
        {
          params = {
            params: { per_page: this.pagination.pageSize }
          };
        }
  
            axios.get('/report/export-attendance-data-report',params).then(response => {
              this.pagination.total = response?.data?.data?.total;
              this.pagination.current = response?.data?.data?.current_page;
              this.pagination.pageSize = response?.data?.data?.per_page;
              
              const start_date = response?.data?.columns[0] || null;
              const end_date = response?.data?.columns[response?.data?.columns?.length - 1] || null;

              this.today_present = response?.today_present || 0;

              this.today_absent = response?.data?.data?.total - response?.today_present;

              this.today_left = response?.left || 0; 

              this.setColumnData(start_date,end_date);
              
              this.data = response?.data?.data?.data || [];
  
              loader.hide();
  
            }).catch((error) => {
  
              loader.hide();
                
              var response = (error.response);
              
              if(error?.response?.status == 401 && response?.data?.message == 'Unauthenticated.'){
              
              localStorage.clear();
              
              notification.open({
                  message: 'Please Login',
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                  },
                });
                
              this.$router.push({name: 'login'}).catch(error => {}) 
              
              }else{
              
              this.errorMessage = error.message;
              notification.open({
                  message: response?.data?.message,
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                  },
                });
                
              }
            });
      },
      setColumnData(startDate,endDate){

          const start_date = moment(startDate);
          const end_date = moment(endDate);
          const dateColumn = [];

          this.sDate = start_date;
          this.eDate = end_date;

          while(start_date.isSameOrBefore(end_date)) {
            dateColumn.push([start_date.format('YYYY-MM-DD'),start_date.format('ddd D')]);
            console.log('asasasasas',dateColumn)
            start_date.add(1, 'day');
          }

          var datesColumn = [];

          dateColumn.forEach(element => {
            datesColumn.push({
                title: element[1],
                dataIndex: element[1],
                key: element[0],
            })
          });

          this.columns = [...this.columns_d,...datesColumn];
      }, 
    },
    mounted()
    {
      this.fetchData()
    }
  };
  </script>
  